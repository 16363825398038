<template>
  <b-row>
    <b-col md="12">
      <div class="entries-table">
        <apexchart
          type="bar"
          height="350"
          :options="chartOptions"
          :series="chartSeries"
        ></apexchart>
        <b-table
          :items="filteredEntries"
          :fields="fields"
          responsive="md"
          class="table-striped"
        >
          <template #cell(project)="data">
            {{ data.item.project.name }}
          </template>
          <template #cell(role)="data">
            {{ data.item.role.name }}
          </template>
          <template slot="cell(active)" slot-scope="data">
            {{ data.item.project.active }}
          </template>
        </b-table>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  name: "ConsultantEntries",
  components: {
    apexchart: VueApexCharts,
  },
  props: ["id"],
  computed: {
    filteredEntries() {
      return this.$store.state.entries.filter(
        (entry) => entry.consultant.id == this.id
      );
    },
    chartSeries() {
      const consultant = this.$store.state.consultants.find(
        (c) => c.id == this.id
      );

      const months = [
        "jan",
        "feb",
        "mar",
        "apr",
        "may",
        "jun",
        "jul",
        "aug",
        "sep",
        "oct",
        "nov",
        "dec",
      ];

      const totalHours = consultant
        ? months.map((month) => this.calcHours(consultant, month))
        : Array(12).fill(0);

      const totalClientHours = consultant
        ? months.map((month) => this.calcClientHours(consultant, month))
        : Array(12).fill(0);

      const bookedHours = this.getBookedHours();

      return [
        {
          name: "Total h",
          data: totalHours,
        },
        {
          name: "Client h",
          data: totalClientHours,
        },
        {
          name: "Booked h",
          data: bookedHours,
        },
      ];
    },
  },
  data() {
    return {
      fields: [
        { key: "project", label: "Project" },
        { key: "role", label: "Role" },
        { key: "active", label: "Active" },
        { key: "Jan", label: "Jan" },
        { key: "Feb", label: "Feb" },
        { key: "Mar", label: "Mar" },
        { key: "Apr", label: "Apr" },
        { key: "May", label: "May" },
        { key: "Jun", label: "Jun" },
        { key: "Jul", label: "Jul" },
        { key: "Aug", label: "Aug" },
        { key: "Sep", label: "Sep" },
        { key: "Oct", label: "Oct" },
        { key: "Nov", label: "Nov" },
        { key: "Dec", label: "Dec" },
      ],
      chartOptions: {
        dataLabels: {
          enabled: true,
          formatter: function(val) {
            return Math.ceil(val);
          },
        },
        chart: {
          id: "vuechart-example",
        },
        colors: ["#008FFB", "#00E396", "#FFA500"],
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        yaxis: {
          labels: {
            formatter: function(value) {
              return value.toFixed(0);
            },
          },
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val;
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
      },
    };
  },
  methods: {
    convertDaysToWorkingHours(numberOfDays) {
      const hours = numberOfDays * 8.5;
      return hours;
    },
    getBookedHours() {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const monthlyData = months.map((month) =>
        this.filteredEntries.reduce(
          (total, entry) => total + (entry[month] || 0),
          0
        )
      );
      return monthlyData;
    },
    calcHours(consultant, month) {
      return (
        this.convertDaysToWorkingHours(
          consultant[`${month}_working_days`] -
            consultant[`${month}_vacation_days`] -
            consultant[`${month}_days_off`]
        ) * consultant[`${month}_workload`]
      );
    },
    calcClientHours(consultant, month) {
      return (
        this.calcHours(consultant, month) * consultant[`${month}_client_share`]
      );
    },
  },
};
</script>

<style scoped>
.entries-table {
  margin-bottom: 1rem;
}
</style>
