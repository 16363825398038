<template>
  <b-container fluid>
    <h1>{{ consultantName }}</h1>
    <h5>{{ consultantJobTitle }}</h5>
    <b-tabs>
      <b-tab title="Entries" active>
        <ConsultantEntries :id="id" />
      </b-tab>
      <b-tab title="Personal Information">
        <ConsultantInformation :id="id" />
      </b-tab>
    </b-tabs>
  </b-container>
</template>

<script>
import ConsultantEntries from "@/components/ConsultantEntries.vue";
import ConsultantInformation from "@/components/ConsultantInformation.vue";
export default {
  name: "Consultant",
  components: {
    ConsultantEntries,
    ConsultantInformation,
  },
  props: ["id"],
  computed: {
    consultantName() {
      const consultant = this.$store.state.consultants.find(
        (c) => c.id == this.id
      );
      return consultant
        ? `${consultant.first_name} ${consultant.last_name}`
        : "";
    },
    consultantJobTitle() {
      const consultant = this.$store.state.consultants.find(
        (c) => c.id == this.id
      );
      return consultant ? `${consultant.job_title}` : "";
    },
  },
};
</script>
