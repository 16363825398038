<template>
  <b-container fluid>
    <h1>{{ projectName }}</h1>
    <b-tabs>
      <b-tab title="Resource overview" active>
        <ProjectResourceOverview :projectId="projectId" />
      </b-tab>
      <b-tab title="Project details">
        <ProjectDetails :projectId="projectId" />
      </b-tab>
    </b-tabs>
  </b-container>
</template>

<script>
import ProjectResourceOverview from "@/components/ProjectResourceOverview.vue";
import ProjectDetails from "@/components/ProjectDetails.vue";
export default {
  name: "Project",
  props: ["projectId"],
  components: {
    ProjectResourceOverview,
    ProjectDetails,
  },
  computed: {
    projectName() {
      const project = this.$store.state.projects.find(
        (project) => project.id === parseInt(this.projectId)
      );
      return project ? project.name : "";
    },
  },
};
</script>
