<template>
  <div>
    <b-row>
      <b-col md="12">
        <h3 class="my-3">Certificates</h3>
        <b-table
          :items="filteredConsultantCertificates"
          :fields="certificateFields"
          responsive="md"
          class="table-striped"
        >
          <template #cell(certificate_name)="data">
            {{ data.item.certificate_name }}
          </template>
          <template #cell(expiration_date)="data">
            {{ data.item.expiration_date }}
          </template>
        </b-table>
      </b-col>

      <b-col md="12">
        <h3 class="my-3">Services</h3>
        <b-table
          :items="filteredConsultantServices"
          :fields="serviceFields"
          responsive="md"
          class="table-striped"
        >
          <template #cell(service_name)="data">
            {{ data.item.service_name }}
          </template>
          <template #cell(service_level)="data">
            {{ data.item.service_level }}
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "ConsultantInformation",
  props: ["id"],
  data() {
    return {
      certificateFields: [
        { key: "certificate_name", label: "Certificate Name" },
        { key: "expiration_date", label: "Expiration Date" },
      ],
      serviceFields: [
        { key: "service_name", label: "Service Name" },
        { key: "service_level", label: "Service Level" },
      ],
    };
  },
  computed: {
    filteredConsultantCertificates() {
      const consultantCertificates = this.$store.state.certificateperconsultants
        .filter((item) => item.consultant.id == this.id)
        .map((item) => ({
          ...item,
          job_title: item.consultant.job_title,
          certificate_name: item.certificate.name,
        }));
      return consultantCertificates;
    },
    filteredConsultantServices() {
      const consultantServices = this.$store.state.serviceperconsultants
        .filter(
          (servicePerConsultant) =>
            servicePerConsultant.consultant.id == this.id
        )
        .map((servicePerConsultant) => {
          return {
            service_name: servicePerConsultant.service.name,
            service_level: servicePerConsultant.servicelevel.name,
          };
        });

      return consultantServices;
    },
  },
};
</script>

<style scoped></style>
