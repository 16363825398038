<template>
  <b-row v-if="projectDetails">
    <b-col md="4">
      <div class="project-details-card mb-4 border-0 shadow-sm">
        <h4 class="mb-3 text-uppercase font-weight-bold">Project Details</h4>
        <div class="project-detail">
          <strong class="text-secondary">Project Name:</strong>
          <p class="mb-0">{{ projectDetails.name }}</p>
        </div>
        <div class="project-detail">
          <strong class="text-secondary">Project An Number:</strong>
          <p class="mb-0">{{ projectDetails.an_number }}</p>
        </div>
        <div class="project-detail">
          <strong class="text-secondary">Mite Position:</strong>
          <p class="mb-0">{{ projectDetails.mite_position }}</p>
        </div>
      </div>
    </b-col>
    <b-col md="4">
      <div class="project-details-card mb-4 border-0 shadow-sm">
        <h4 class="mb-3 text-uppercase font-weight-bold">Project Details</h4>
        <div class="project-detail">
          <strong class="text-secondary">Project Lead:</strong>
          <p class="mb-0">{{ projectDetails.project_lead }}</p>
        </div>
        <div class="project-detail">
          <strong class="text-secondary">Deputy:</strong>
          <p class="mb-0">{{ projectDetails.deputy }}</p>
        </div>
        <div class="project-detail">
          <strong class="text-secondary">From:</strong>
          <p class="mb-0">{{ projectDetails.from }}</p>
        </div>
      </div>
    </b-col>
    <b-col md="4">
      <div class="project-details-card mb-4 border-0 shadow-sm">
        <h4 class="mb-3 text-uppercase font-weight-bold">Project Details</h4>
        <div class="project-detail">
          <strong class="text-secondary">To:</strong>
          <p class="mb-0">{{ projectDetails.to }}</p>
        </div>
        <div class="project-detail">
          <strong class="text-secondary">Project Hours:</strong>
          <p class="mb-0">{{ projectDetails.project_hours }}</p>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "ProjectDetails",
  props: ["projectId"],
  computed: {
    projectDetails() {
      return this.$store.state.projects.find(
        (project) => project.id === parseInt(this.projectId)
      );
    },
  },
  data() {
    return {
      fields: [
        { key: "consultant", label: "Consultant" },
        { key: "role", label: "Role" },
        { key: "Jan", label: "Jan" },
        { key: "Feb", label: "Feb" },
        { key: "Mar", label: "Mar" },
        { key: "Apr", label: "Apr" },
        { key: "May", label: "May" },
        { key: "Jun", label: "Jun" },
        { key: "Jul", label: "Jul" },
        { key: "Aug", label: "Aug" },
        { key: "Sep", label: "Sep" },
        { key: "Oct", label: "Oct" },
        { key: "Nov", label: "Nov" },
        { key: "Dec", label: "Dec" },
      ],
    };
  },
};
</script>

<style scoped>
.project-details-card {
  background-color: #f8f9fa;
  border-radius: 15px;
  padding: 2rem;
}

.project-details-card h4 {
  border-bottom: 2px solid #007bff;
  padding-bottom: 0.5rem;
}

.project-detail {
  margin-bottom: 1rem;
}

.project-detail strong {
  display: block;
  margin-bottom: 0.2rem;
}
</style>
