import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Consultant from "@/views/Consultant.vue";
import Project from "@/views/Project.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/consultant/:id/:name",
    name: "Consultant",
    component: Consultant,
    props: true,
  },
  {
    path: "/project/:projectId/:projectName",
    name: "Project",
    component: Project,
    props: true,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
