<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <div class="entries-table">
          <h1>Entries</h1>
          <apexchart
            type="bar"
            height="350"
            :options="chartOptions"
            :series="computedChartSeries"
          ></apexchart>

          <b-tabs>
            <b-tab title="Resources Search" active>
              <b-row class="mt-2">
                <b-form-input
                  class="w-25 ml-2"
                  v-model="consultantNameSearch"
                  placeholder="Consultant Name"
                ></b-form-input>

                <b-form-select
                  v-model="teamSearch"
                  :options="teams"
                  class="w-25 ml-2"
                  placeholder="Select Team"
                ></b-form-select>

                <b-form-select
                  v-model="serviceSearch"
                  :options="services"
                  class="w-25 ml-2"
                  placeholder="Select Service"
                ></b-form-select>
                <b-button
                  variant="primary"
                  @click="clearFilters"
                  class="mb-5 ml-2"
                >
                  Clear Filters
                </b-button>
              </b-row>
            </b-tab>
            <b-tab title="Projects Search">
              <b-row class="mt-2">
                <b-form-input
                  class="w-25 ml-2"
                  v-model="projectNameSearch"
                  placeholder="Project Name"
                ></b-form-input>

                <b-form-input
                  class="w-25 ml-2"
                  v-model="anNumberSearch"
                  placeholder="AN Number"
                ></b-form-input>

                <b-form-select
                  v-model="projectActiveSearch"
                  :options="projectActiveOptions"
                  class="ml-2 mb-3 w-25"
                ></b-form-select>

                <b-button
                  variant="primary"
                  @click="clearFilters"
                  class="mb-5 ml-2"
                >
                  Clear Filters
                </b-button>
              </b-row>
            </b-tab>
          </b-tabs>

          <b-table
            :items="searchQuery"
            :fields="fields"
            responsive="md"
            class="table-striped"
          >
            <template slot="cell(consultant)" slot-scope="data">
              <router-link
                :to="
                  `/consultant/${data.item.consultant.id}/${data.item.consultant.first_name}-${data.item.consultant.last_name}`
                "
              >
                <span
                  v-b-tooltip.hover.bottom.html="
                    getConsultantTooltip(data.item.consultant)
                  "
                >
                  {{ data.item.consultant.first_name }}
                  {{ data.item.consultant.last_name }}
                </span>
              </router-link>
            </template>

            <template slot="cell(project)" slot-scope="data">
              <router-link
                :to="
                  `/project/${data.item.project.id}/${data.item.project.name}`
                "
              >
                <span
                  v-b-tooltip.hover.bottom.html="
                    getProjectTooltip(data.item.project)
                  "
                >
                  {{ data.item.project.name }}
                </span>
              </router-link>
            </template>
            <template slot="cell(active)" slot-scope="data">
              {{ data.item.project.active }}
            </template>
            <template slot="cell(role)" slot-scope="data">
              {{ data.item.role.name }}
            </template>
          </b-table>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  name: "Home",
  components: {
    apexchart: VueApexCharts,
  },
  computed: {
    teams() {
      return [
        { text: "Select Team", value: null, disabled: true },
        ...this.$store.state.teams.map((team) => ({
          text: team.name,
          value: team.name,
        })),
      ];
    },

    services() {
      return [
        { text: "Select Service", value: null, disabled: true },
        ...this.$store.state.services.map((service) => ({
          text: service.name,
          value: service.name,
        })),
      ];
    },
    matchingProjects() {
      if (!this.serviceSearch) {
        return [];
      }

      return this.$store.state.projects
        .filter(
          (project) =>
            project.services &&
            project.services.some((service) =>
              service.name
                .toLowerCase()
                .includes(this.serviceSearch.toLowerCase())
            )
        )
        .map((project) => project.name);
    },
    formattedEntries() {
      return this.$store.state.entries.map((entry) => {
        const consultant = this.$store.state.consultants.find(
          (consultant) => consultant.id === entry.consultant.id
        );

        return {
          ...entry,
          consultant,
          project: entry.project,
        };
      });
    },

    searchQuery() {
      return this.formattedEntries.filter((entry) => {
        const matchesConsultantName = this.consultantNameSearch
          ? `${entry.consultant.first_name} ${entry.consultant.last_name}`
              .toLowerCase()
              .includes(this.consultantNameSearch.toLowerCase())
          : true;

        const matchesTeam = this.teamSearch
          ? entry.consultant.teams.some((team) =>
              team.name.toLowerCase().includes(this.teamSearch.toLowerCase())
            )
          : true;

        const matchesService = this.serviceSearch
          ? this.$store.state.serviceperconsultants.some(
              (item) =>
                item.consultant.id === entry.consultant.id &&
                item.service.name
                  .toLowerCase()
                  .includes(this.serviceSearch.toLowerCase())
            )
          : true;

        const matchesProjectName = this.projectNameSearch
          ? entry.project.name
              .toLowerCase()
              .includes(this.projectNameSearch.toLowerCase())
          : true;

        const matchesAnNumber = this.anNumberSearch
          ? entry.project.an_number
              .toLowerCase()
              .includes(this.anNumberSearch.toLowerCase())
          : true;

        const matchesProjectActive =
          this.projectActiveSearch !== null
            ? entry.project.active === this.projectActiveSearch
            : true;

        return (
          matchesConsultantName &&
          matchesTeam &&
          matchesService &&
          matchesProjectName &&
          matchesAnNumber &&
          matchesProjectActive
        );
      });
    },

    computedChartSeries() {
      let filteredEntries = this.searchQuery;
      return [
        {
          name: "Total h",
          data: this.getTotalHoursPerMonth(filteredEntries),
        },
        {
          name: "Client h",
          data: this.getTotalClientHoursPerMonth(filteredEntries),
        },
        {
          name: "Booked h",
          data: this.getMonthlyConsultantCounts(filteredEntries),
        },
      ];
    },
  },
  data() {
    return {
      consultantNameSearch: "",
      teamSearch: null,
      serviceSearch: null,
      projectNameSearch: "",
      anNumberSearch: "",
      projectActiveSearch: null,
      projectActiveOptions: [
        { value: null, text: "Select Project Status" },
        { value: true, text: "Active ON" },
        { value: false, text: "Active OFF" },
      ],
      fields: [
        { key: "consultant", label: "Consultant" },
        { key: "project", label: "Project" },
        { key: "role", label: "Role" },
        { key: "active", label: "Active" },
        { key: "Jan", label: "Jan" },
        { key: "Feb", label: "Feb" },
        { key: "Mar", label: "Mar" },
        { key: "Apr", label: "Apr" },
        { key: "May", label: "May" },
        { key: "Jun", label: "Jun" },
        { key: "Jul", label: "Jul" },
        { key: "Aug", label: "Aug" },
        { key: "Sep", label: "Sep" },
        { key: "Oct", label: "Oct" },
        { key: "Nov", label: "Nov" },
        { key: "Dec", label: "Dec" },
      ],
      chartOptions: {
        dataLabels: {
          enabled: true,
          formatter: function(val) {
            return Math.ceil(val);
          },
        },
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        yaxis: {
          labels: {
            formatter: function(value) {
              return value.toFixed(0);
            },
          },
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val;
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
      },
    };
  },
  methods: {
    getConsultantTooltip(consultant) {
      return `
        <div><strong>Job Title:</strong> ${consultant.job_title}</div>
      `;
    },

    getProjectTooltip(project) {
      return `
        <div><strong>Project Name:</strong> ${project.name}</div>
        <div><strong>AN Number:</strong> ${project.an_number}</div>
        <div><strong>Mite Position:</strong> ${project.mite_position}</div>
        <div><strong>Project Lead:</strong> ${project.project_lead ||
          "N/A"}</div>
        <div><strong>Deputy:</strong> ${project.deputy || "N/A"}</div>
        <div><strong>From:</strong> ${project.from}</div>
        <div><strong>To:</strong> ${project.to}</div>
        <div><strong>Project Hours:</strong> ${project.project_hours}</div>
      `;
    },

    convertDaysToWorkingHours(numberOfDays) {
      const hours = numberOfDays * 8.5;
      return hours;
    },

    getMonthlyConsultantCounts(entries) {
      const monthlyCounts = Array(12).fill(0);
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      entries.forEach((entry) => {
        months.forEach((month, index) => {
          monthlyCounts[index] += entry[month] || 0;
        });
      });

      return monthlyCounts;
    },
    getTotalHoursPerMonth(entries) {
      const months = [
        "jan",
        "feb",
        "mar",
        "apr",
        "may",
        "jun",
        "jul",
        "aug",
        "sep",
        "oct",
        "nov",
        "dec",
      ];
      const requiredProps = (month) => [
        `${month}_working_days`,
        `${month}_vacation_days`,
        `${month}_days_off`,
        `${month}_workload`,
      ];

      const calcHours = (consultant, month) =>
        this.convertDaysToWorkingHours(
          consultant[`${month}_working_days`] -
            consultant[`${month}_vacation_days`] -
            consultant[`${month}_days_off`]
        ) * consultant[`${month}_workload`];

      return months.map((month) => {
        const uniqueConsultants = new Set();
        return entries.reduce((sum, entry) => {
          const consultant = entry.consultant;
          if (
            requiredProps(month).every(
              (prop) => consultant[prop] !== undefined
            ) &&
            !uniqueConsultants.has(consultant.id)
          ) {
            uniqueConsultants.add(consultant.id);
            return sum + calcHours(consultant, month);
          }
          return sum;
        }, 0);
      });
    },

    getTotalClientHoursPerMonth(entries) {
      const months = [
        "jan",
        "feb",
        "mar",
        "apr",
        "may",
        "jun",
        "jul",
        "aug",
        "sep",
        "oct",
        "nov",
        "dec",
      ];

      const hasRequiredProps = (consultant, month) => {
        const props = [
          `${month}_working_days`,
          `${month}_vacation_days`,
          `${month}_days_off`,
          `${month}_workload`,
          `${month}_client_share`,
        ];
        return props.every((prop) => consultant[prop] !== undefined);
      };

      const calcClientHours = (consultant, month) =>
        this.convertDaysToWorkingHours(
          consultant[`${month}_working_days`] -
            consultant[`${month}_vacation_days`] -
            consultant[`${month}_days_off`]
        ) *
        consultant[`${month}_workload`] *
        consultant[`${month}_client_share`];

      return months.map(
        (month) =>
          entries.reduce(
            (sum, { consultant }) =>
              hasRequiredProps(consultant, month) &&
              !sum.consultants.has(consultant.id)
                ? (sum.consultants.add(consultant.id),
                  (sum.total += calcClientHours(consultant, month)),
                  sum)
                : sum,
            { total: 0, consultants: new Set() }
          ).total
      );
    },

    clearFilters() {
      (this.consultantNameSearch = ""),
        (this.teamSearch = null),
        (this.serviceSearch = null),
        (this.projectNameSearch = ""),
        (this.anNumberSearch = "");
      this.projectActiveSearch = null;
    },
  },
};
</script>

<style scoped>
.entries-table {
  margin-bottom: 1rem;
}
</style>
