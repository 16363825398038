import Vue from "vue";
import Vuex from "vuex";
import set from "lodash/set";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    projects: [],
    consultants: [],
    roles: [],
    services: [],
    certificates: [],
    certificateperconsultants: [],
    teams: [],
    entries: [],
    servicelevels: [],
    serviceperconsultants: [],
  },
  mutations: {
    SET_API_DATA(state, payload) {
      set(state, payload.property, payload.value);
    },
  },
  getters: {},
  actions: {},
  modules: {},
});
