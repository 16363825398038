<template>
  <b-row>
    <b-col md="12">
      <div class="project-table">
        <b-table
          :items="filteredEntries"
          :fields="fields"
          responsive="md"
          class="table-striped"
        >
          <template #cell(consultant)="data">
            {{ data.item.consultant.first_name }}
            {{ data.item.consultant.last_name }}
          </template>
          <template #cell(role)="data">
            {{ data.item.role.name }}
          </template>
        </b-table>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "ProjectResourceOverview",
  props: ["projectId"],
  computed: {
    filteredEntries() {
      return this.$store.state.entries.filter(
        (entry) => entry.project.id === parseInt(this.projectId)
      );
    },
  },
  data() {
    return {
      fields: [
        { key: "consultant", label: "Consultant" },
        { key: "role", label: "Role" },
        { key: "Jan", label: "Jan" },
        { key: "Feb", label: "Feb" },
        { key: "Mar", label: "Mar" },
        { key: "Apr", label: "Apr" },
        { key: "May", label: "May" },
        { key: "Jun", label: "Jun" },
        { key: "Jul", label: "Jul" },
        { key: "Aug", label: "Aug" },
        { key: "Sep", label: "Sep" },
        { key: "Oct", label: "Oct" },
        { key: "Nov", label: "Nov" },
        { key: "Dec", label: "Dec" },
      ],
    };
  },
};
</script>

<style scoped>
.project-table {
  margin-bottom: 1rem;
}
</style>
