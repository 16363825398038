<template>
  <div id="app">
    <TheHeader />
    <transition appear name="route" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>
<script>
import axios from "axios";
import TheHeader from "@/components/TheHeader.vue";

const fetchData = (endpoint) =>
  axios.get(`${process.env.VUE_APP_URL}/${endpoint}?_limit=-1`);

export default {
  name: "App",
  components: {
    TheHeader,
  },
  mounted() {
    const endpoints = [
      "projects",
      "consultants",
      "roles",
      "services",
      "certificates",
      "certificateperconsultants",
      "teams",
      "entries",
      "servicelevels",
      "serviceperconsultants",
    ];

    axios.all(endpoints.map(fetchData)).then((responseArr) => {
      responseArr.forEach(({ data }, index) => {
        this.commitApiData(endpoints[index], data);
      });
    });
  },
  methods: {
    commitApiData(property, value) {
      this.$store.commit("SET_API_DATA", { property, value });
    },
  },
};
</script>
<style lang="scss">
// Animation effect
.route-enter,
.route-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}
.route-enter-active,
.route-leave-active {
  transition: all 0.3s ease;
}
.route-enter-to,
.route-leave {
  opacity: 1;
  transform: translateY(0);
}
</style>
