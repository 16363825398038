<template>
  <div class="container-fluid">
    <div class="row menu">
      <div class="container">
        <div class="text-center">
          <router-link to="/"
            ><img src="@/assets/logo-main.svg" alt="" class="img-fluid w-25"
          /></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.menu {
  background: #f3f3f3;
  padding: 25px 0;
  margin-bottom: 50px;
}
</style>

<script>
export default {
  name: "Header",
};
</script>
